<template>
    <div>
    </div>
</template>

<script>
export default {
    name: "organPayCode",
    data() {
        return {
        }
    },

    computed:{
        user() {
            return this.$store.state.user;
        },
    },
    // 在 Vue 组件中使用 $watch 监听 Vuex 数据的变化
    watch: {
        user() {}
    },

    props: {
        sign: {
            type: String,
            default() {
                return {}
            }
        },
    },

    mounted() {
        this.corpPay();
    },

    methods: {
        // 创建分账转账对象申请 返回订单
        corpPay() {
            var that = this;
            that.newApi.corpPay({sign:that.sign}).then((res) => {
                if(res.isSuccess == 1){
                    const routeData = that.$router.resolve({path: '/toPay/' + res.data});
                    //到newAdapay页面去跳转支付页面
                    that.$emit('change',{url:routeData.href});
                }
            })
        },

    }
}
</script>

<style scoped>

</style>
