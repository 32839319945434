<template>
    <div class="tabs-result">
        <div>
            <el-tabs class="tabs-box" @tab-click="checkoutResult" :value="selectTabs" type="border-card">
                <el-tab-pane label="填写成果" :name="'0'" v-if="serviceData.status == 4 && user.uuid === serviceData.sellerUuid">
                    <Editor :tinymce_height="'600'" id="1" :innerDrawer="innerDrawer = true" :value="resultInfo.content" ref="child"></Editor>
                    <div style="display: flex; justify-content: space-between; margin: 20px 0 10px 0">
                        <el-upload  action="" multiple :http-request="uploadFile" :show-file-list="false" >
                            <div class="foot-btn hand op" style="border: 1px #FF9900 solid; color: #FF9900">+ 上传附件</div>
                        </el-upload>
                        <div @click="submitResult" class="foot-btn hand op" style="background-color: #FF9900; color: #FFFFFF">提交成果</div>
                    </div>
                </el-tab-pane>

                <template v-for="item in serviceData.resultList">
                    <el-tab-pane :label="items.createTime" :name="String(items.id)" v-for="items in item" :key="items.id">
                        <div style="padding:10px 40px">
                            <div v-html="items.content"></div>
                            <div v-if="items.examineContnet" style="background-color: #F8F8F8; overflow: hidden; margin-top: 20px">
                                <div style="padding: 20px">
                                    <div>
                                        <div style="margin-bottom: 2px">
                                            <span style="font-weight: 600; color: #2970FF;">{{serviceData.nickName}}</span>
                                            <span style="margin-left: 12px; font-size: 14px; color: #999999">{{items.updateTime}}</span>
                                        </div>
                                        <div>{{items.examineContnet}}</div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin: 30px 0; display: flex; justify-content: end" v-if="serviceData.status === 5 && user.uuid === serviceData.uuid && items.status === 1">
                                <div @click="selectOption(items.id, 1)" class="foot-btn hand op" style="color: #FF0000; border: 1px #FF0000 solid; margin-right: 20px">拒绝成果</div>
                                <div @click="selectOption(items.id, 2)" class="foot-btn hand op" style="color: #67C23A; border: 1px #67C23A solid; margin-right: 0">通过成果</div>
                            </div>
                            <div style="margin: 30px 0; display: flex; justify-content: end" v-if="serviceData.status === 4 && user.uuid === serviceData.sellerUuid && items.status === 2 && serviceData.resultList[0][0].id === items.id">
                                <div @click="editResult(items)" class="foot-btn hand op" style="color: #FFFFFF; background-color: #2970FF; margin-right: 0">编辑成果</div>
                            </div>
                        </div>
                    </el-tab-pane>
                </template>
            </el-tabs>
            <div style="margin-top: 20px">
                <div class="files">
                    <div class="file-box" v-for="(item, index) in fileList" :key="index">
                        <img src="https://oss.baigongbao.com/img/file.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                        <span style="font-size: 18px; vertical-align: middle">{{item.name}}</span>
                        <div style="float: right">
                                        <span class="hand op" style="background-color: #FFFFFF; margin-right: 10px; text-align: center; color: #FF9800; padding: 2px 10px; border-radius: 20px"
                                              @click="downloadFiles(item.url, item.name, item.hash)"><i class="el-icon-download"></i> 下载</span>
                            <span v-if="serviceData.sellerUuid === user.uuid && serviceData.status === 4" class="hand op" style="background-color: #FFFFFF; text-align: center; color: #FF9800; padding: 2px 10px; border-radius: 20px"
                                  @click="removeFile(item, index)"><i class="el-icon-delete"></i> 删除</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!--拒绝/通过建议弹窗-->
        <el-dialog :visible.sync="showContent" width="44%" :show-close="false" class="event-form" :close-on-click-modal="false">
            <div class="top">
                {{proposal}}
                <img src="../../../../assets/imgs/index/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="showContent = false">
            </div>
            <div style="padding: 20px 40px; overflow: hidden">
                <el-input
                        type="textarea"
                        v-model="examineContnet"
                        :rows="8">
                </el-input>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                    <div class="hand op" @click="showContent = false" style="border: 1px solid #DDDDDD;font-size: 16px; padding: 4px 16px; border-radius: 5px; margin: 10px">取消</div>
                    <div class="hand op" @click="payResultOrder" style="background-color: #2970FF; color: #FFFFFF; font-size: 16px; padding: 4px 16px; border-radius: 5px; margin: 10px">确认</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

    import Editor from "@/components/common/Editor";
    export default {
        name: "TabsResult",
        components: {Editor},
        data() {
            return {
                // 成果
                content: '',
                resultInfo: {
                    content: '',
                    files: []
                },

                // 成果验收
                showContent: false,
                selectId: '',
                selectType: '',
                examineContnet: '',
                fileList: [],
                selectTabs: '',
            proposal:"填写内容"
            }
        },

        props: {
            serviceData: {
                type: Object,
                default(){
                    return {
                    }
                }
            }
        },

        computed: {
            user() {
                return this.$store.state.user
            }
        },

        watch: {
            serviceData: {
                handler() {
                    this.selectTabs = String(this.serviceData.resultList[0][0].id)
                    this.$EventBus.$emit("checkoutResult", {id: String(this.serviceData.resultList[0][0].id)});
                    this.fileList = this.serviceData.resultList[0][0].files;
                    for (const item of this.fileList) {
                        let obj = {
                            img_url:"",
                            file_name:item.name,
                            file_size:item.size,
                        };
                        this.resultInfo.files.push(obj)
                    }
                }
            }
        },

        created: function () {

        },
        mounted: function () {
            this.selectTabs = String(this.serviceData.resultList[0][0].id)
            this.$EventBus.$emit("checkoutResults", {id: String(this.serviceData.resultList[0][0].id)});
            this.fileList = this.serviceData.resultList[0][0].files;
            for (const item of this.fileList) {
                let obj = {
                    img_url:"",
                    file_name:item.name,
                    file_size:item.size,
                };
                this.resultInfo.files.push(obj)
            }
        },
        methods: {
            // 切换成果
            checkoutResult(item) {
                this.$EventBus.$emit("checkoutResults", {id: item.name});
            },

            // 编辑成果
            editResult(item) {
                this.$refs.child.set_content(item.content)
                this.selectTabs = '0';
            },

            // 选择验收操作
            selectOption(id, type) {
            if(type == 1){
                this.proposal = "修改意见";
            }else{
                this.proposal = "通过备注";
            }
                this.showContent = true;
                this.selectId = id;
                this.selectType = type;
            },

            // 确认服务验收支付
            payResultOrder() {
            this.loading = true;
                if (this.selectType == 1) {
                    this.newApi.addResultIdea({id: this.selectId, examineContnet: this.examineContnet}).then(res => {
                    this.loading = false;
                        if (res.isSuccess === 1) {
                            this.utils.sus(res.data);
                            this.selectId = '';
                            this.selectType = '';
                            this.examineContnet = '';
                            this.$parent.getServiceInfo();
                            this.showContent = false;
                        }
                    })
                }
                if (this.selectType == 2) {
                    var param = {};
                param.orderNo = this.serviceData.payOrderNo;
                    param.number = 1;
                    for (const item of this.serviceData.payInfo) {
                        if (item.isCompleted == 1) {
                            param.number ++;
                        }
                    }
                    this.newApi.verifyServiceReceive(param).then(res => {
                    this.loading = false;
                        if (res.isSuccess === 1) {
                            this.resultOption();
                        }
                    })
                }
            },

            // 成果验收操作
            resultOption() {
                this.newApi.saveServiceResult({id: this.selectId, examineContnet: this.examineContnet}).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data);
                        this.selectId = '';
                        this.selectType = '';
                        this.examineContnet = '';
                        this.$parent.getServiceInfo();
                        this.showContent = false;
                    }
                })
            },

            // 提交成果
            submitResult() {
                var that = this;
                var param = {};
                var number = 1;
                param.serviceOrder = this.$route.params.id;
                param.content = this.$refs.child.get_content();
                for (const item of this.serviceData.payInfo) {
                    if (item.isCompleted == 1) {
                        number ++;
                    }
                }
                param.phase = number;
                console.log(param.phase)
                if (!param.content) {
                    this.utils.err('请填写成果内容');
                    return;
                }
                //处理附件统一上传
                if (that.fileList.length > 0 && that.resultInfo.files.length > 0){
                    let filesPromise = [];
                    for (let i = 0; i < that.resultInfo.files.length; i++) {
                        filesPromise.push(new Promise(function (resolve) {
                            if(that.fileList[i].uid) {
                                that.utils.upload(that.fileList[i],function(url){
                                    if (!url){
                                        return false;
                                    }
                                    let obj = {};
                                    obj.img_url = that.ossUrl + 'img/file.png';
                                    obj.name = that.fileList[i].name.substring(0, that.fileList[i].name.lastIndexOf("."));
                                    obj.size = that.fileList[i].size;
                                    obj.type = that.utils.get_suffix(that.fileList[i].name);
                                    obj.url = url;
                                    resolve(obj);
                                })
                            } else {
                                resolve(that.fileList[i])
                            }

                        }))
                    }
                    Promise.all(filesPromise).then(res=>{
                        param.files = JSON.stringify(res);
                        that.newApi.addServiceResult(param).then(res => {
                            if (res.isSuccess === 1) {
                                that.utils.sus(res.data)
                                this.$parent.getServiceInfo();
                            }
                        })
                    })
                } else {
                    param.files = [];
                    that.newApi.addServiceResult(param).then(res => {
                        if (res.isSuccess === 1) {
                            that.utils.sus(res.data)
                            this.$parent.getServiceInfo();
                        }
                    })
                }
            },

            //附件上传
            uploadFile:function(params){
                var that = this;
                var file = params.file;
                that.fileList.push(file);
                var obj = {
                    img_url:"",
                    file_name:file.name,
                    file_size:file.size,
                };
                that.resultInfo.files.push(obj);
            },

            // 下载附件
            downloadFiles(url, name, hash) {
                this.utils.download(url, name,hash)
            },

            // 移除附件
            removeFile(item, index) {
                var that = this;
                if (item.url) {
                    that.utils.confirm('删除后无法恢复，确定删除该附件吗？', function () {
                        that.utils.deletePrivateOss(item.url, function () {
                            that.fileList.splice(index, 1);
                            that.resultInfo.files.splice(index,1);
                            that.utils.sus('删除成功');
                        })
                    })
                } else {
                    that.fileList.splice(index, 1);
                    that.resultInfo.files.splice(index,1);
                    that.utils.sus('删除成功');
                }
            },
        }
    }
</script>

<style scoped>
    .tabs-result .files{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        line-height: 26px;
        font-size: 15px;
    }

    .tabs-result .files::-webkit-scrollbar {
        display: none;
    }

    .tabs-result .file-box {
        height: 32px;
        padding: 8px 10px;
        line-height: 32px;
        margin-bottom: 4px;
        background-color: #F1F1F1;
    }
    .foot-btn {
        width: 108px;
        height: 24px;
        font-size: 16px;
        border-radius: 5px;
        text-align: center;
        padding: 6px;
    }
    .event-form .top {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
    }

    .event-form .title-form {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .tabs-box>>>img {
        width: 100%;
        height: 100%;
    }

    /deep/ .event-form .el-dialog__header {
        padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
        padding: 0;
    }
</style>
