<template>
    <div class="expert-state">
        <div class="plate">
            <div class="dynamic">
                <div>
                    <div class="node">
                        <div class="no">1</div>
                        <div class="titles">订单已生成，须缴纳保证金</div>
                    </div>
                    <div class="nodeItem" v-if="order.depositList.indexOf(order.uuid) !== -1">
                        <div class="circle"></div>
                        委托方已支付保证金
                    </div>
                    <div class="nodeItem" v-if="order.depositList.indexOf(order.sellerUuid) !== -1">
                        <div class="circle"></div>
                        服务方已支付保证金
                    </div>
                </div>

                <div v-if="order.status >= 3 && order.status < 10">
                    <div class="node">
                        <div class="no">2</div>
                        <div class="titles">双方签订合同，订单已生效</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        <div>等待委托方支付费用</div>
                    </div>
                </div>

                <div v-if="order.status >= 4 && order.status < 10">
                    <div class="node">
                        <div class="no">3</div>
                        <div class="titles">支付成功，服务方开始工作</div>
                    </div>
                    <div class="nodeItem" v-if="order.expertList.length > 0">
                        <div class="circle"></div>
                        <div style="line-height:25px;">专家已加入服务订单</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        <div style="line-height:25px;">您可以随时查看订单进度</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        <div>或者<span class="bt hand op">联系委托方</span></div>
                    </div>
                </div>

                <div v-if="order.status >= 5 && order.status < 10">
                    <div class="node">
                        <div class="no">4</div>
                        <div class="titles">服务方已提交成果</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        您可以查看并提出修改意见
                    </div>
                </div>

                <div v-if="order.status === 9">
                    <div class="node">
                        <div class="no">5</div>
                        <div class="titles">委托方验收通过</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        服务订单完成
                    </div>
                </div>

                <div v-if="order.status === 10">
                    <div class="node">
                        <div class="no">2</div>
                        <div class="titles">订单已撤销</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        服务订单结束
                    </div>
                </div>

                <div v-if="order.status === 11">
                    <div class="node">
                        <div class="no">2</div>
                        <div class="titles">订单已退款</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        服务订单结束
                    </div>
                </div>

                <div v-if="order.status === 7">
                    <div class="node">
                        <div class="no">5</div>
                        <div class="titles">服务订单退款中</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        委托方申请退款
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        退款申请生效
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ExpertState",
    components: {},
    data() {
        return {}
    },
    props: {
        order: {
            type: Object,
            default(){
                return {}
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {

    },
    methods: {}
}
</script>

<style scoped>
.plate .dynamic {
    padding: 20px 26px;
}

.plate .dynamic .node {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #031F88;
}

.plate .dynamic .node .no {
    width: 24px;
    height: 24px;
    border: 1px solid #031F88;
    box-sizing: border-box;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    line-height: 22px;
    margin-right: 16px;
}

.plate .dynamic .node .titles {
    font-size: 16px;
    font-weight: 600;
}

.plate .dynamic .nodeItem {
    margin-left: 12px;
    border-left: 1px solid #031F88;
    padding: 10px 0 5px 27px;
    line-height: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.plate .dynamic .nodeItem .bt {
    padding: 4px 10px;
    background-color: #FF0000;
    font-size: 12px;
    color: #ffffff;
    border-radius: 5px;
    margin: 0 8px;
}

.plate .dynamic .nodeItem .no-bt {
    padding: 4px 10px;
    background-color: #C6C6C6;
    font-size: 12px;
    color: #ffffff;
    border-radius: 5px;
    margin: 0 8px;
}
</style>