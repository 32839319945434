<template>
    <div>
        <el-dialog :visible.sync="showMessage" @close="closePayBox" width="25%" :show-close="false" :modal="true" :close-on-click-modal="false" :close-on-press-escape="false">
            <div style="padding: 20px">
                <div>请在新页面完成支付</div>
                <div style="margin-bottom: 20px">支付完成前请不要关闭窗口</div>
                <span class="hand op" @click.once="closePayBox" style="text-align: center; padding: 2px 10px; border-radius: 5px; background-color: #FF9900; color: #FFFFFF">已完成支付</span>
                <span class="hand op" style="color: #2970FF; margin-left: 20px">支付遇到问题？</span>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "IsPayShow",
        data() {
            return {
            }
        },
        props: {
            showMessage: {
                type: Boolean,
                default: false
            }
        },
        mounted() {
        },
        methods: {
            // 点击支付完成操作
            closePayBox() {
                location.reload();
            },
        }
    }
</script>

<style scoped>

</style>
