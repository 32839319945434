<template>
    <div class="tabs-expert">
        <el-tabs class="tabs" :value="selectTabs">
            <div v-for="(item, index) in serviceData.resultList" :key="index">
                <div v-for="(items, indexs) in item" :key="indexs">
                    <div v-if="serviceData.expertList.findIndex(item => item.uuid === user.uuid) !== -1">
                        <el-tab-pane :label="items.createTime" :name="String(items.id)">
                            <div v-if="serviceData.status === 4 || serviceData.status === 5">
                                <Editor :tinymce_height="'600'" :id="String(items.id)" :innerDrawer="innerDrawer = true" :value="resultInfo.content" ref="expertChild"></Editor>
                                <div style="display: flex; justify-content: space-between; margin: 20px 0 10px 0">
                                    <el-upload  action="" multiple :http-request="uploadFile" :show-file-list="false" >
                                        <div class="foot-btn hand op" style="border: 1px #FF9900 solid; color: #FF9900">+ 上传附件</div>
                                    </el-upload>
                                    <div @click="submitResult(items.id, indexs)" class="foot-btn hand op" style="float: right; margin: 0 0 10px 0; color: #FFFFFF; background-color: #2970FF">上传意见</div>
                                </div>
                            </div>

                            <div style="margin: 0 0 20px 0">
                                <div class="files">
                                    <div class="file-box" v-for="(item, index) in fileList" :key="index">
                                        <img src="https://oss.baigongbao.com/img/file.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                                        <span style="font-size: 18px; vertical-align: middle">{{item.name}}</span>
                                        <div style="float: right">
                                <span class="hand op" style="background-color: #FFFFFF; margin-right: 10px; text-align: center; color: #FF9800; padding: 2px 10px; border-radius: 20px"
                                      @click="downloadFiles(item.url, item.name, item.hash)"><i class="el-icon-download"></i> 下载</span>
                                            <span class="hand op" style="background-color: #FFFFFF; text-align: center; color: #FF9800; padding: 2px 10px; border-radius: 20px"
                                                  @click="removeFile(item, index)"><i class="el-icon-delete"></i> 删除</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </div>

                    <el-tab-pane :label="items.createTime" :name="String(items.id)" >
                        <div v-if="items.expertPinion.length > 0">
                            <div style="padding:10px;" v-for="(expert, key) in items.expertPinion" :key="key">
                                <div class="tabs-box" style="box-shadow: 0 2px 12px 0 rgba(0,0,0,.1); padding: 20px" :key="key + keys">
                                    <div v-if="expert.edit === true">
                                        <Editor :tinymce_height="'600'" :id="String(items.id) + expert.id" :innerDrawer="innerDrawer = true" :value="editResultInfo.content" ref="expert"></Editor>
                                        <div style="display: flex; justify-content: space-between; margin: 20px 0 10px 0">
                                            <el-upload  action="" multiple :http-request="uploadEditFile" :show-file-list="false" >
                                                <div class="foot-btn hand op" style="border: 1px #FF9900 solid; color: #FF9900">+ 上传附件</div>
                                            </el-upload>
                                            <div style="display: flex; margin-bottom: 10px">
                                                <div @click="cancelEditResult(index, indexs, key)" class="foot-btn hand op" style="float: right; margin-right: 10px; background-color: #EBEBEB">取消修改</div>
                                                <div @click="submitEditResult(expert.id, key)" class="foot-btn hand op" style="float: right; color: #FFFFFF; background-color: #2970FF">修改意见</div>
                                            </div>
                                        </div>
                                        <div style="margin-top: 20px">
                                            <div class="files">
                                                <div class="file-box" v-for="(item, index) in editFileList" :key="index">
                                                    <img src="https://oss.baigongbao.com/img/file.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                                                    <span style="font-size: 18px; vertical-align: middle">{{item.name}}</span>
                                                    <div style="float: right">
                                    <span class="hand op" style="background-color: #FFFFFF; margin-right: 10px; text-align: center; color: #FF9800; padding: 2px 10px; border-radius: 20px" v-if="item.url"
                                          @click="downloadFiles(item.url, item.name, item.hash)"><i class="el-icon-download"></i> 下载</span>
                                                        <span class="hand op" style="background-color: #FFFFFF; text-align: center; color: #FF9800; padding: 2px 10px; border-radius: 20px"
                                                              @click="removeEditFile(item, index)"><i class="el-icon-delete"></i> 删除</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!expert.edit">
                                        <div style="display: flex; margin-bottom: 6px">
                                            <img :src="expert.headIco" style="width: 48px; height: 48px; border-radius: 50%; margin-right: 10px">
                                            <div>
                                                <div style="color: #2970FF; font-weight: 600; margin-bottom: 4px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden">{{expert.nickName}}</div>
                                                <div style="color: #999999; font-size: 14px">{{expert.major}} | {{expert.updateTime}}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <div v-html="expert.content"></div>
                                            <div style="margin-top: 10px">
                                                <div class="files">
                                                    <div class="file-box" v-for="(file, fIndex) in expert.files" :key="fIndex">
                                                        <img src="https://oss.baigongbao.com/img/file.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                                                        <span style="font-size: 18px; vertical-align: middle">{{file.name}}</span>
                                                        <div style="float: right">
                                    <span class="hand op" style="background-color: #FFFFFF; margin-right: 10px; text-align: center; color: #FF9800; padding: 2px 10px; border-radius: 20px" v-if="file.url"
                                          @click="downloadFiles(file.url, file.name, file.hash)"><i class="el-icon-download"></i> 下载</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="expert.uuid === user.uuid && (serviceData.status === 4 || serviceData.status === 5)" style="margin: 20px 0 0 0; display: flex; justify-content: end">
                                            <div @click="editResult(expert, index, indexs, key)" class="foot-btn hand op" style="color: #FFFFFF; background-color: #2970FF; margin-right: 0">编辑意见</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="items.expertPinion.length === 0">
                            <NullBox data="专家审查意见"></NullBox>
                        </div>
                    </el-tab-pane>


                </div>
            </div>
        </el-tabs>
    </div>
</template>

<script>

import Editor from "@/components/common/Editor";
import NullBox from "./NullBox";

export default {
    name: "TabsExpert",
    components: {NullBox, Editor},
    data() {
        return {
            // 成果
            content: '',
            resultInfo: {
                content: '',
                files: []
            },

            editResultInfo: {
                content: '',
                files: []
            },

            editFileList: [],

            fileList: [],

            selectTabs: '',

            keys: new Date()
        }
    },

    props: {
        serviceData: {
            type: Object,
            default(){
                return {
                }
            }
        }
    },

    computed: {
        user() {
            return this.$store.state.user
        }
    },

    watch: {
    },

    updated() {

    },

    created: function () {

    },
    mounted: function () {
        this.selectTabs = String(this.serviceData.resultList[0][0].id);
        this.$EventBus.$on("checkoutResults", (param) => {
            this.selectTabs = String(param.id);
            this.fileList = [];
            this.resultInfo = {
                content: '',
                files: []
            };
        })
    },
    methods: {
        // 编辑意见
        editResult(item, index, indexs, key) {
            this.serviceData.resultList[index][indexs].expertPinion[key].edit = true;
            this.keys = new Date();
            this.$nextTick(() => {
                this.editResultInfo.content = item.content;
                this.$refs.expert[key].set_content(item.content);
                console.log(this.$refs.expert[key].get_content())
                for (const file of item.files) {
                    let obj = {
                        img_url:"",
                        file_name:file.name,
                        file_size:file.size,
                    };
                    this.editResultInfo.files.push(obj);
                    this.editFileList.push(file);
                }
            });
        },

        // 取消修改意见
        cancelEditResult(index, indexs, key) {
            this.serviceData.resultList[index][indexs].expertPinion[key].edit = false;
            this.key = Date.now();
            this.$nextTick(() => {
                this.editResultInfo.content = '';
                this.editResultInfo.files = [];
                this.editFileList = [];
            });
        },

        // 提交修改意见
        submitEditResult(id, key) {
            var that = this;
            var param = {};
            param.id = id;
            param.content = this.$refs.expert[key].get_content()
            if (!param.content) {
                this.utils.err('请填写专家意见内容');
                return;
            }
            //处理附件统一上传
            if (that.editFileList.length > 0 && that.editResultInfo.files.length > 0){
                let filesPromise = [];
                for (let i = 0; i < that.editResultInfo.files.length; i++) {
                    filesPromise.push(new Promise(function (resolve) {
                        if(that.editFileList[i].uid) {
                            that.utils.upload(that.editFileList[i],function(url){
	                            if (!url){
		                            return false;
	                            }
                                resolve(url);
                            })
                        } else {
                            resolve(that.editFileList[i])
                        }

                    }))
                }
                Promise.all(filesPromise).then(res=>{
                    param.files = JSON.stringify(res);
                    that.newApi.updateExpertIdea(param).then(res => {
                        if (res.isSuccess === 1) {
                            that.utils.sus(res.data);
                            that.editResultInfo.content = '';
                            that.editResultInfo.files = [];
                            that.editFileList = [];
                            that.$parent.getServiceInfo();
                        }
                    })
                })
            } else {
                param.files = [];
                that.newApi.updateExpertIdea(param).then(res => {
                    if (res.isSuccess === 1) {
                        that.utils.sus(res.data);
                        that.editResultInfo.content = '';
                        that.editResultInfo.files = [];
                        that.editFileList = [];
                        that.$parent.getServiceInfo();
                    }
                })
            }
        },

        // 提交意见
        submitResult(id, index) {
            var that = this;
            var param = {};
            param.serviceOrderNo = this.$route.params.id;
            param.content = this.$refs.expertChild[index].get_content();
            param.resultId = id;
            if (!param.content) {
                this.utils.err('请填写专家意见内容');
                return;
            }
            //处理附件统一上传
            if (that.fileList.length > 0 && that.resultInfo.files.length > 0){
                let filesPromise = [];
                for (let i = 0; i < that.resultInfo.files.length; i++) {
                    filesPromise.push(new Promise(function (resolve) {
                        if(that.fileList[i].uid) {
                            that.utils.upload(that.fileList[i],function(url){
	                            if (!url){
		                            return false;
	                            }
                                resolve(url);
                            })
                        } else {
                            resolve(that.fileList[i])
                        }

                    }))
                }
                Promise.all(filesPromise).then(res=>{
                    param.files = JSON.stringify(res);
                    that.newApi.addExpertIdea(param).then(res => {
                        if (res.isSuccess === 1) {
                            that.utils.sus(res.data)
                            that.resultInfo.content = '';
                            that.resultInfo.files = [];
                            that.fileList = [];
                            that.$refs.expertChild[index].set_content('');
                            that.$parent.getServiceInfo();
                        }
                    })
                })
            } else {
                param.files = [];
                that.newApi.addExpertIdea(param).then(res => {
                    if (res.isSuccess === 1) {
                        that.utils.sus(res.data)
                        that.resultInfo.content = '';
                        that.resultInfo.files = [];
                        that.fileList = [];
                        that.$refs.expertChild[index].set_content('');
                        that.$parent.getServiceInfo();
                    }
                })
            }
        },

        uploadEditFile:function(params){
            var that = this;
            var file = params.file;
            that.editFileList.push(file);
            var obj = {
                img_url:"",
                file_name:file.name,
                file_size:file.size,
            };
            that.editResultInfo.files.push(obj);
        },

        //附件上传
        uploadFile:function(params){
            var that = this;
            var file = params.file;
            that.fileList.push(file);
            var obj = {
                img_url:"",
                file_name:file.name,
                file_size:file.size,
            };
            that.resultInfo.files.push(obj);
        },

        // 下载附件
        downloadFiles(url,name,hash) {
            this.utils.download(url,name,hash)
        },

        removeEditFile(item, index) {
            var that = this;
            if (item.url) {
                that.utils.confirm('删除后无法恢复，确定删除该附件吗？', function () {
                    that.utils.deletePrivateOss(item.url, function () {
                        that.editFileList.splice(index, 1);
                        that.editResultInfo.files.splice(index,1);
                        console.log(that.editResultInfo.files)
                        that.utils.sus('删除成功');
                    })
                })
            } else {
                that.editFileList.splice(index, 1);
                that.editResultInfo.files.splice(index,1);
                that.utils.sus('删除成功');
            }
        },

        removeFile(item, index) {
            var that = this;
            if (item.url) {
                that.utils.confirm('删除后无法恢复，确定删除该附件吗？', function () {
                    that.utils.deletePrivateOss(item.url, function () {
                        that.fileList.splice(index, 1);
                        that.resultInfo.files.splice(index,1);
                        that.utils.sus('删除成功');
                    })
                })
            } else {
                that.fileList.splice(index, 1);
                that.resultInfo.files.splice(index,1);
                that.utils.sus('删除成功');
            }
        },
    }
}
</script>

<style scoped>
    .tabs-expert .files{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        line-height: 26px;
        font-size: 15px;
    }

    .tabs-expert .files::-webkit-scrollbar {
        display: none;
    }

    .tabs-expert .file-box {
        height: 32px;
        padding: 8px 10px;
        line-height: 32px;
        margin-bottom: 4px;
        background-color: #F1F1F1;
    }
    .foot-btn {
        width: 108px;
        height: 24px;
        font-size: 16px;
        border-radius: 5px;
        text-align: center;
        padding: 6px;
    }
    .event-form .top {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        color: #444444;
        border: 1px solid #CECFD0;
        height: 60px;
        box-sizing: border-box;
        line-height: 60px;
    }

    .event-form .title-form {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .tabs-box>>>img {
        width: 100%;
        height: 100%;
    }

    /deep/ .el-tabs__header {
        display: none;
    }

    /deep/ .event-form .el-dialog__header {
        padding: 0;
    }

    /deep/ .event-form .el-dialog__body {
        padding: 0;
    }
</style>
