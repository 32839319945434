<template>
    <div class="client-state">
        <div class="plate">
            <div class="dynamic">
                <div>
                    <div class="node">
                        <div class="no">1</div>
                        <div class="titles">订单已生成，须缴纳保证金</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        <div v-if="order.depositList.indexOf(order.uuid) === -1 && order.status !== 10">
                            委托方支付保证金
                            <span v-if="order.commonOrderNo" @click="toPayGuarantee(order.commonOrderNo)" class="bt hand op">继续支付</span>
                            <span v-else @click="toShowGuarantee" class="bt hand op">支付</span>
                        </div>
                        <div v-else>委托方支付保证金<span class="no-bt">支付</span></div>
                    </div>
                    <div class="nodeItem" v-if="order.depositList.indexOf(order.uuid) !== -1">
                        <div class="circle"></div>
                        委托方已支付保证金
                    </div>
                    <div class="nodeItem" v-if="order.depositList.indexOf(order.sellerUuid) !== -1">
                        <div class="circle"></div>
                        服务方已支付保证金
                    </div>
                </div>

                <div v-if="order.status >= 3 && order.status < 10">
                    <div class="node">
                        <div class="no">2</div>
                        <div class="titles">双方签订合同，订单已生效</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        <div v-if="order.status === 3">
                            请支付服务费用
                            <span v-if="order.commonOrderNo" @click="toPayServiceFee(order.commonOrderNo)" class="bt hand op">继续支付</span>
                            <span v-else @click="toShowServiceFee" class="bt hand op">支付</span>
                        </div>
                        <div v-else>请支付服务费用<span class="no-bt">支付</span></div>
                    </div>
                </div>

                <div v-if="order.status >= 4 && order.status < 10">
                    <div class="node">
                        <div class="no">3</div>
                        <div class="titles">支付成功，服务方开始工作</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        <div style="line-height:25px;">您可以随时查看订单进度</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        <div>或者<span class="bt hand op" @click="toMessage(order.sellerUuid, order.sellerName)">联系服务方</span></div>
                    </div>
                    <div class="nodeItem" v-if="order.expertList.length > 0">
                        <div class="circle"></div>
                        <div style="line-height:25px;">专家已加入服务订单</div>
                    </div>
                </div>

                <div v-if="order.status >= 5 && order.status < 10">
                    <div class="node">
                        <div class="no">4</div>
                        <div class="titles">服务方已提交成果</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        您可以查看并验收成果
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        <div>或者联系专家进行评审</div>
                    </div>
                </div>
                <div v-if="order.status === 7">
                    <div class="node">
                        <div class="no">5</div>
                        <div class="titles">服务订单退款中</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        委托方申请退款
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        退款申请生效
                    </div>
                </div>
                <div v-if="order.status === 9">
                    <div class="node">
                        <div class="no">5</div>
                        <div class="titles">订单完成</div>
                    </div>
                </div>
                <div v-if="order.status === 6">
                  <div class="node">
                    <div class="no">5</div>
                    <div class="titles">委托方验收通过</div>
                  </div>
                  <div class="nodeItem">
                    <div class="circle"></div>
                    <div>服务订单待评价<span class="bt hand op" @click="showAppraise = true">评价</span></div>
                  </div>
                </div>
                <div v-if="order.status === 10">
                    <div class="node">
                        <div class="no">2</div>
                        <div class="titles">订单已撤销</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        服务订单结束
                    </div>
                </div>
                <div v-if="order.status === 11">
                    <div class="node">
                        <div class="no">2</div>
                        <div class="titles">订单已退款</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        服务订单结束
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :close-on-click-modal="false" class="show-message" :visible.sync="showMessage" width="940px">
            <div class="dialog-title">
                <div style="margin-left: 40%">
                    <span style="color: #0084FF">{{nickName}}</span>
                    <span>的私信</span>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="padding: 20px">
                <ToMessage :uuid="uuid" :type="1"></ToMessage>
            </div>
        </el-dialog>

        <!--服务评价弹窗-->
        <el-dialog :close-on-click-modal="false" :visible.sync="showAppraise" width="32%" :show-close="false" class="event-form">
          <div class="top">
            服务评价
            <img src="../../imgs/cancel.png" style="width: 32px; height: 32px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="closeEvent">
          </div>
          <div style="padding: 20px 40px">
            <div style="margin-bottom: 40px; display: flex;">
              <div style="flex: 1">
                <div class="title-form">服务成果</div>
                <el-rate v-model="appraiseData.result" color="#FF0000"></el-rate>
              </div>
              <div style="flex: 1">
                <div class="title-form">服务专业</div>
                <el-rate v-model="appraiseData.major" color="#FF0000"></el-rate>
              </div>
              <div style="flex: 1">
                <div class="title-form">服务态度</div>
                <el-rate v-model="appraiseData.manner" color="#FF0000"></el-rate>
              </div>
            </div>
            <div style="margin-bottom: 40px">
              <div class="title-form">总体评价</div>
              <el-input
                  type="textarea"
                  v-model="appraiseData.content"
                  :show-word-limit="true"
                  maxlength="500"
                  :rows="5"
                  placeholder="请输入序列说明">
              </el-input>
            </div>
            <div style="display: flex; justify-content: center">
              <div class="foot-btn hand op" style="background-color: #2970FF; color: #FFFFFF;" @click="addAppraise">立即评价</div>
            </div>
          </div>
        </el-dialog>
    </div>
</template>

<script>

import ToMessage from "../order/ToMessage";
export default {
    name: "ClientState",
    components: {ToMessage},
    data() {
        return {
            showMessage: false,
            uuid: '',
            nickName: '',

            // 服务评价
            showAppraise: false,
            appraiseData: {
              result: 0,
              major: 0,
              manner: 0,
              content: ''
            },
        }
    },
    props: {
        order: {
            type: Object,
            default(){
                return {}
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {

    },
    methods: {
        // 评价
        addAppraise() {
          let params = {};
          params.evaluateId = this.order.id;
          params.shopId = this.order.shopId;
          params.type = 1;
          params.fid = 0;
          params.serviceAchievements = this.appraiseData.result;
          params.serviceMajor = this.appraiseData.major;
          params.serviceAttitude = this.appraiseData.manner;
          params.content = this.appraiseData.content;
          if (!params.content) {
            this.utils.msg('请填写评价内容');
            return
          }
          if (!params.serviceAchievements || !params.serviceMajor || !params.serviceAttitude) {
            this.utils.msg('请对商品进行评分');
            return;
          }
          this.newApi.addShopAndServiceEvaluate(params).then(res => {
            if (res.isSuccess === 1) {
              this.utils.sus(res.data);
              this.showAppraise = false;
              this.$parent.getServiceInfo()
            }
          })
        },

        // 关闭评价弹窗
        closeEvent() {
          this.showAppraise = false
        },

        // 私信
        toMessage(uuid, name) {
            this.uuid = uuid;
            this.nickName = name;
            this.showMessage = true
        },

        // 展示保证金弹窗
        toShowGuarantee() {
            if (this.order.paymentType.payType == 0) {
                this.$parent.selectGuarantee()
            } else {
                this.$parent.getGuarantee()
            }
        },

        // 展示服务金弹窗
        toShowServiceFee() {
            this.$parent.getServiceFee()
        },

        // 继续支付保证金
        toPayGuarantee(orderNo) {
            const routeData = this.$router.resolve({path: '/user/money/toPay/' + orderNo, query: {type: 2}});
            this.utils.b(routeData.href);
        },

        // 继续支付服务金
        toPayServiceFee(orderNo) {
            const routeData = this.$router.resolve({path: '/user/money/toPay/' + orderNo, query: {type: 2}});
            this.utils.b(routeData.href);
        },
    }
}
</script>

<style scoped>
    .plate .dynamic {
        padding: 20px 26px;
    }

    .plate .dynamic .node {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #031F88;
    }

    .plate .dynamic .node .no {
        width: 24px;
        height: 24px;
        border: 1px solid #031F88;
        box-sizing: border-box;
        border-radius: 50%;
        text-align: center;
        font-size: 12px;
        line-height: 22px;
        margin-right: 16px;
    }

    .plate .dynamic .node .titles {
        font-size: 16px;
        font-weight: 600;
    }

    .plate .dynamic .nodeItem {
        margin-left: 12px;
        border-left: 1px solid #031F88;
        padding: 10px 0 5px 27px;
        line-height: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .plate .dynamic .nodeItem .bt {
        padding: 4px 10px;
        background-color: #FF0000;
        font-size: 12px;
        color: #ffffff;
        border-radius: 5px;
        margin: 0 8px;
    }

    .plate .dynamic .nodeItem .no-bt {
        padding: 4px 10px;
        background-color: #C6C6C6;
        font-size: 12px;
        color: #ffffff;
        border-radius: 5px;
        margin: 0 8px;
    }

    .dialog-title {
        height: 30px;
        padding: 10px 0;
        font-weight: 600;
        font-size: 20px;
    }

    .event-form .top {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      color: #444444;
      border: 1px solid #CECFD0;
      height: 60px;
      box-sizing: border-box;
      line-height: 60px;
    }

    .client-state .title-form {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .client-state .foot-btn {
      width: 108px;
      height: 24px;
      font-size: 16px;
      border-radius: 5px;
      text-align: center;
      padding: 6px;
    }

    /deep/ .show-message .el-dialog__header{
        padding: 0;
    }

    /deep/ .show-message .el-dialog__body{
        padding: 0;
    }
</style>
