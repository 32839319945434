<template>
    <div class="server-state">
        <div class="plate">
            <div class="dynamic">
                <div>
                    <div class="node">
                        <div class="no">1</div>
                        <div class="titles">订单已生成，须缴纳保证金</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        <div v-if="order.depositList.indexOf(order.sellerUuid) === -1 && order.status !== 10">
                            服务方支付保证金
                            <span v-if="order.commonOrderNo" @click="toPayGuarantee(order.commonOrderNo)" class="bt hand op">继续支付</span>
                            <span v-else @click="toShowGuarantee" class="bt hand op">支付</span>
                        </div>
                        <div v-else>服务方支付保证金<span class="no-bt">支付</span></div>
                    </div>
                    <div class="nodeItem" v-if="order.depositList.indexOf(order.uuid) !== -1">
                        <div class="circle"></div>
                        委托方已支付保证金
                    </div>
                    <div class="nodeItem" v-if="order.depositList.indexOf(order.sellerUuid) !== -1">
                        <div class="circle"></div>
                        服务方已支付保证金
                    </div>
                </div>

                <div v-if="order.status >= 3 && order.status < 10">
                    <div class="node">
                        <div class="no">2</div>
                        <div class="titles">双方签订合同，订单已生效</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        <div>等待委托方支付费用</div>
                    </div>
                </div>
                <div v-if="order.status >= 4 && order.status < 10">
                    <div class="node">
                        <div class="no">3</div>
                        <div class="titles">支付成功，服务方开始工作</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        <div style="line-height:25px;">您可以随时上传成果</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        <div>或者<span class="bt hand op" @click="toMessage(order.uuid, order.nickName)">联系委托方</span></div>
                    </div>
                    <div class="nodeItem" v-if="order.expertList.length > 0">
                        <div class="circle"></div>
                        <div style="line-height:25px;">专家已加入服务订单</div>
                    </div>
                </div>

                <div v-if="order.status >= 5 && order.status < 10">
                    <div class="node">
                        <div class="no">4</div>
                        <div class="titles">服务方已提交成果</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        等待专家进行评审
                    </div>
                </div>

                <div v-if="order.status === 9">
                    <div class="node">
                        <div class="no">5</div>
                        <div class="titles">委托方验收通过</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        服务订单完成
                    </div>
                </div>

                <div v-if="order.status === 10">
                    <div class="node">
                        <div class="no">2</div>
                        <div class="titles">订单已撤销</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        服务订单结束
                    </div>
                </div>

                <div v-if="order.status === 11">
                    <div class="node">
                        <div class="no">2</div>
                        <div class="titles">订单已退款</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        服务订单结束
                    </div>
                </div>

                <div v-if="order.status === 7">
                    <div class="node">
                        <div class="no">5</div>
                        <div class="titles">服务订单退款中</div>
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        委托方申请退款
                    </div>
                    <div class="nodeItem">
                        <div class="circle"></div>
                        退款申请生效
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :close-on-click-modal="false" class="show-message" :visible.sync="showMessage" width="940px">
            <div class="dialog-title">
                <div style="margin-left: 40%">
                    <span style="color: #0084FF">{{nickName}}</span>
                    <span>的私信</span>
                </div>
            </div>
            <el-divider></el-divider>
            <div style="padding: 20px">
                <ToMessage :uuid="uuid" :type="1"></ToMessage>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import ToMessage from "../order/ToMessage";
export default {
    name: "ServerState",
    components: {ToMessage},
    data() {
        return {
            showMessage: false,
            uuid: '',
            nickName: '',
        }
    },
    props: {
        order: {
            type: Object,
            default(){
                return {}
            }
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {

    },
    methods: {
        // 私信
        toMessage(uuid, name) {
            this.uuid = uuid;
            this.nickName = name;
            this.showMessage = true
        },

        // 展示保证金弹窗
        toShowGuarantee() {
            if (this.order.paymentType.payType == 0) {
                this.$parent.selectGuarantee()
            } else {
                this.$parent.getGuarantee()
            }
        },

        // 继续支付保证金
        toPayGuarantee(orderNo) {
            const routeData = this.$router.resolve({path: '/user/money/toPay/' + orderNo, query: {type: 2}});
            this.utils.b(routeData.href);
        },
    }
}
</script>

<style scoped>
    .plate .dynamic {
        padding: 20px 26px;
    }

    .plate .dynamic .node {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #031F88;
    }

    .plate .dynamic .node .no {
        width: 24px;
        height: 24px;
        border: 1px solid #031F88;
        box-sizing: border-box;
        border-radius: 50%;
        text-align: center;
        font-size: 12px;
        line-height: 22px;
        margin-right: 16px;
    }

    .plate .dynamic .node .titles {
        font-size: 16px;
        font-weight: 600;
    }

    .plate .dynamic .nodeItem {
        margin-left: 12px;
        border-left: 1px solid #031F88;
        padding: 10px 0 5px 27px;
        line-height: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .plate .dynamic .nodeItem .bt {
        padding: 4px 10px;
        background-color: #FF0000;
        font-size: 12px;
        color: #ffffff;
        border-radius: 5px;
        margin: 0 8px;
    }

    .plate .dynamic .nodeItem .no-bt {
        padding: 4px 10px;
        background-color: #C6C6C6;
        font-size: 12px;
        color: #ffffff;
        border-radius: 5px;
        margin: 0 8px;
    }

    .circle {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: #031F88;
        margin-right: 10px;
        flex: 0 0 auto;
        margin-top: 8px;
    }

    .dialog-title {
        height: 30px;
        padding: 10px 0;
        font-weight: 600;
        font-size: 20px;
    }

    /deep/ .show-message .el-dialog__header{
        padding: 0;
    }

    /deep/ .show-message .el-dialog__body{
        padding: 0;
    }
</style>
