<template>
    <div class="null-box">
        <div style="margin-bottom: 40px">
            <img src="../../imgs/null.png" style="width: 300px;">
            <div style="font-size: 22px; font-weight: 600; color: #B2B9C3">暂无{{data}}</div>
        </div>
    </div>
</template>

<script>

export default {
    name: "NullBox",
    components: {},
    data() {
        return {}
    },
    props: {
        data: {
            type: String
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    created: function () {

    },
    mounted: function () {

    },
    methods: {}
}
</script>

<style scoped>
    .null-box {
        width: 100%;
        text-align: center;
    }
</style>